.app {
  text-align: center;
}
.text {
  font-size: 90%;
}

@media (min-width:640px) {
  .text {
    font-size: 12px;
  }
}

.centered {
  text-align: center;
}

.small-margin {
  margin-bottom: 5px;
}

.medium-margin {
  margin-bottom: 15px;
}

.page-container {
  box-sizing: border-box;
  /* border: 1px solid red; */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container {
  box-sizing: border-box;
  /* border: 1px solid green; */
  max-width: 380px;
  height: 100%;
}

#container-1 {
  background-image: linear-gradient(rgba(41,128,185,1) 0%, rgba(109,213,250,1) 47%, rgba(255,255,255,1) 100%);
}

#first-content {
  background-image: url('./assets/image-1.png');
  background-position: 20% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hero-text-image {
  margin-top: -20%;
  width: 70%;
  filter: invert(100);
}

#container-2 {
  background-image: linear-gradient(0deg, rgba(109,213,250,1) 0%, rgba(255,255,255,1) 58%);
}

#second-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#bismillah {
  width: 70%;
}

#mempelai {
  width: 75%;
}

#container-3 {
  background-image: linear-gradient(rgba(109,213,250,1) 32%, rgba(255,255,255,1) 58%);
}

#third-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#wedding-day {
  font-size: 125%;
  font-style: italic;
  font-weight: 700;
}
#wedding-time {
  font-size: 135%;
}
#middle-image {
  margin-top: 15%;
  margin-bottom: 15%;
  width: 70%;
}
#location-1 {
  font-size: 125%;
  font-weight: 700;
}

#container-4 {
  background-image: linear-gradient(0deg, rgba(109,213,250,1) 0%, rgba(255,255,255,1) 58%);
}

#fourth-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-image: url('./assets/image-3.png');
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 50%;
}

#maps-button {
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  width: 80%;
  margin-top: 10%;
  margin-bottom: 10%;
  border: 1px solid #2980B9;
  border-radius: 10px;
  background-color: #2980B9;
  color: white;
  font-weight: 700;
  padding: 2%;
  box-shadow: 0 1px 2px 0 rgb(50 50 50 / 50%), 0 1px 8px 0 rgb(50 50 50 / 70%);
}